/**
 * Created by Tukta on 15/7/2560.
 */
import React, { Component } from 'react'
import { Redirect, useNavigate , Link } from 'react-router-dom';
import axios from 'axios'
import { APIURL } from '../../../config/config'
import {connect} from 'react-redux';
import DatePicker from 'react-datepicker'
import moment from 'moment'
import 'react-datepicker/dist/react-datepicker.css';
import NumberFormat from 'react-number-format';
import {AlertError, AlertSuccess, AlertWarning} from '../../Alert/Alert'
import { addMonths, addDays, format } from 'date-fns';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx-js-style';
import {ExcelUtil} from "../../../utils/excelUtil";
import { LineNotify } from '../../../config/lineNotify'
import { NumberUtil } from '../../../utils/number-util'


class DebtorsForm extends Component{

    constructor(props){
        super(props);
        this.state = {
            lists: [],
            user: {},
            load_data: true,
            danger: false,
            debtorsadd: false,
            branch:[],
            customer: {},
            debtors_list: [],
            customer_list:[],
            is_customer_select: false,
            customer_id:'',
            debtors_id:0,
            debtors:'',
            car_id:'',
            // car: '',
            date_pay : new Date(),
            duration_period:[],
            count_period:[],
            amount_of_debt:0,
            amount_pay_period:0, // จำนวนเงินต่องวด
            bank_list: [],
            debtors_type: [],
            debtors_type_id: '',
            is_customer_search: false,
            car_search: '',
            is_car_search: false,
            car_lists: [],
            is_car_select: false,
            car_select: {},
            interest_type: 'ดอกเบี้ยคงที่',
            amount_before_interest: '',
            interest_rate: '',
            // calculate_note: '',
            amount_interest: '',
            pay_details: [],
            pay_details_old: [],
            debtors_name: '',
            is_redirect: false
        }
        this.handleDatePayStart = this.handleDatePayStart.bind(this);
        this.loadBankData = this.loadBankData.bind(this);
        this.loadDebtorType = this.loadDebtorType.bind(this);
        this.searchCar = this.searchCar.bind(this);
        this.searchCustomer = this.searchCustomer.bind(this);
        this.onCarSelect = this.onCarSelect.bind(this);
        this.loadCustomerData = this.loadCustomerData.bind(this);
        this.onInterestTypeChange = this.onInterestTypeChange.bind(this);
        this.calculateInterest = this.calculateInterest.bind(this);
        this.handleOnSubmitAddDebtors = this.handleOnSubmitAddDebtors.bind(this);
        this.exportToExcel = this.exportToExcel.bind(this);
        this.onChangePayDetail = this.onChangePayDetail.bind(this);
    }

    onInterestTypeChange(e){
        let v = e.target.value
        // ดอกเบี้ยคงที่ || ดอกเบี้ยลดต้นลดดอก
        this.setState({
            interest_type: v,
            amount_interest: ''
        }, () => this.calculateInterest())
    }

    handleDatePayStart(date) {
        this.setState({
            date_pay: date
        }, () => {
            if (this.state.amount_pay_period !== '' && Number(this.state.amount_pay_period) > 0 ) {
                this.calculateInterest()
            }
        });
    }

    calculateInterest() {
        let duration_period = this.state.duration_period
        // <div className= {this.state.interest_type === 'ดอกเบี้ยลดต้นลดดอก' ? "hidden" : "form-group col-6" } >
        if (this.state.amount_before_interest === '') {
            return;
        }

        if (this.state.interest_rate === '') {
            return;
        }

        if (duration_period.length === 0) {
            return;
        }

        let amount_before_interest = Number(this.state.amount_before_interest)
        let interest_rate = Number(this.state.interest_rate)
        let date_pay = this.state.date_pay

        let duration_period_id = Number(this.refs.duration_period_id.value);


        let durations = duration_period.filter(duration => duration.duration_period_id === duration_period_id)

        // จำนวนงวดที่จ่าย
        let duration_period_month = durations[0].duration_period_month

        let count_period_id = Number(this.refs.count_period_id.value);
        let count_period = this.state.count_period
        let periods = count_period.filter(period => period.count_period_id === count_period_id)

        // ระยะเวลาต่องวด
        let count_period_month = periods[0].count_period_month

        let count_month = 0;
        let count_date = 0
        let count_by = 'month'

        if (Number.isInteger(count_period_month)) {
            count_by = 'month'
            count_month = (duration_period_month * count_period_month)
        } else {
            if(!isNaN(parseFloat(count_period_month))) {
                // is float แบบวัน
                count_by = 'date'
                let count_date_string = String(count_period_month)
                let count_arr = count_date_string.split(".")
                count_date = parseInt(String(count_arr[1]))
                count_month = (duration_period_month * (count_date/30))
            }
        }

        let count_year = parseFloat(count_month/12.0).toFixed(2)

        // ดอกเบี้ยคงที่ || ดอกเบี้ยลดต้นลดดอก
        let interest_type = this.state.interest_type

        if (interest_type === 'ดอกเบี้ยคงที่') {
            let amount_interest = (Number(amount_before_interest) * Number(interest_rate)/100.0)*count_year
            let amount_of_debt = Number(amount_before_interest) + amount_interest

            let amount_pay_period_text = String(amount_of_debt/duration_period_month)
            let amount_pay_period = parseFloat(amount_pay_period_text).toFixed(2)

            let pay_details = []

            for(let i=0; i < duration_period_month ;i++) {
                let next_date = null
                if (count_by === 'month') {
                    let amount_month = i * count_period_month
                    next_date = addMonths(date_pay, amount_month)
                } else if (count_by === 'date') {
                     let amount_date = i * count_date
                    next_date = addDays(date_pay, amount_date)
                }

                let next_date_text = format(next_date, 'yyyy-MM-dd')
                let order = i + 1
                let interest_period_bath = Number(amount_interest)/ duration_period_month
                let interest_period_bath_fix2 = parseFloat(String(interest_period_bath)).toFixed(2)
                let capital = amount_pay_period - interest_period_bath_fix2

                let pay_detail = {
                    order: order,
                    pay_date: next_date_text,
                    amount_pay_period: amount_pay_period,
                    capital: capital,
                    interest_period_bath: interest_period_bath_fix2
                }
                pay_details.push(pay_detail)
            }

            this.setState({
                amount_interest: amount_interest,
                amount_of_debt: amount_of_debt,
                amount_pay_period: amount_pay_period,
                pay_details: pay_details,
                pay_details_old: pay_details,
            })
        } else if (interest_type === 'ดอกเบี้ยลดต้นลดดอก') {

            if (this.state.amount_pay_period === '') {
                return
            }

            if (Number(this.state.amount_pay_period) <= 0) {
                return
            }

            let amount_pay_period = Number(this.state.amount_pay_period)
            let sum_interest = 0
            let sum_capital = 0
            let pay_details = []

            for(let i=0; i < duration_period_month ;i++){
                // let amount_month = i * count_period_month
                // let next_date = addMonths(date_pay, amount_month)

                let next_date = null
                let real_interest_rate = 0
                if (count_by === 'month') {
                    let amount_month = i * count_period_month
                    next_date = addMonths(date_pay, amount_month)
                    real_interest_rate = 12/count_period_month
                } else if (count_by === 'date') {
                    let amount_date = i * count_date
                    next_date = addDays(date_pay, amount_date)
                    let month_date = count_date/30
                    real_interest_rate = 12/month_date
                }

                let next_date_text = format(next_date, 'yyyy-MM-dd')

                let order = i + 1
                // =+$C$3*C2/12
                let capital_remain = amount_before_interest - sum_capital
                let interest_period_percent = interest_rate/(real_interest_rate)
                let interest_period_bath = Number(capital_remain)*(Number(interest_period_percent)/100)
                let interest_period_bath_fix2 = parseFloat(String(interest_period_bath)).toFixed(2)
                let capital = amount_pay_period - interest_period_bath_fix2
                let capital_fix2 = parseFloat(capital).toFixed(2)

                sum_interest += Number(interest_period_bath_fix2)
                sum_capital += Number(capital)

                let pay_detail = {
                    order: order,
                    pay_date: next_date_text,
                    amount_pay_period: amount_pay_period,
                    capital: capital_fix2,
                    interest_period_bath: interest_period_bath_fix2
                }
                if (interest_period_bath_fix2 >=0 ){
                    pay_details.push(pay_detail)
                } else {
                    break
                }

            }

            let sum_interest_fix2 = parseFloat(sum_interest).toFixed(2)
            let amount_of_debt_check =  parseFloat(Number(sum_capital) + Number(sum_interest_fix2)).toFixed(2)
            let amount_of_debt =  parseFloat(Number(amount_before_interest) + Number(sum_interest_fix2)).toFixed(2)
            // let amount_of_debt_check = parseFloat(Number(amount_before_interest) + Number(sum_interest_fix2)).toFixed(2)

            let amount_pay_period_cal = amount_of_debt/duration_period_month

            if (sum_capital < amount_before_interest) {
                let amount_pay_period_cal_fix = parseFloat(String(amount_pay_period_cal)).toFixed(2)
                AlertError('ยอดจ่ายขั้นต่ำน้อยเกินไป ระบุอย่างน้อย '+amount_pay_period_cal_fix+' บาท หรือเพิ่มงชำระ');
                return
            }

            this.setState({
                pay_details: pay_details,
                pay_details_old: pay_details,
                amount_interest: sum_interest_fix2,
                amount_of_debt: amount_of_debt,
            })
            // addMonths
        }
    }

    searchCustomer(){
        let search_text = this.refs.search_customer_name.value ;
        // alert(search_text);
        if(search_text.length < 3){
            return
        }

        this.getDataCustomerSearch(search_text);
    }

    searchCar(e) {
        e.preventDefault()
        let value = e.target.value
        this.setState({
            car_search: value
        }, () => {
            if (value.length >= 3) {
                axios.get(APIURL + '/car_sell/search_debt/'+value)
                  .then(res => {
                      this.setState({
                          car_lists: res.data,
                          is_car_search: true
                      })
                  }).catch(error => {
                    console.log('error: ', error)
                    this.setState({
                        car_lists: [],
                        is_car_search: false
                    })
                })
            }
        })
    }

    onCarSelect(car) {
        let customer_id = car.customer_id
        let car_id = car.car_id

        this.setState({
            is_car_select: true,
            is_car_search: false,
            car_select: car,
            customer_id: customer_id,
            car_id: car_id
        }, () => {
            this.loadCustomerData()
        })
    }

    loadCustomerData () {
        axios.get(APIURL + '/customer/'+this.state.customer_id)
          .then(res => {
              this.setState({
                  customer: res.data[0],
                  is_customer_select: true,
                  is_customer_search: false,
              })
          }).catch(error => {
            alert("พบข้อผิดพลาด : "+error)
            this.setState({customer: {}})
        })
    }

    getDataCustomerSearch (search_text) {
        axios.get(APIURL + '/customer/search/'+search_text)
          .then(res => {
              this.setState({
                  customer_list: res.data,
                  is_customer_search: true
              })
          })
          .catch(error => {
              this.setState({customer_list: []})
          })
    }

    onCustomerSelect(customer) {
        /// alert(customer.customer_id)
        this.setState({
            is_customer_select: true,
            is_customer_search: false,
            customer: customer,
            customer_id: customer.customer_id},()=>{
        });
    }

    formatDate(date) {
        var d = new Date(date),
            month = '' + (d.getMonth() + 1),
            day = '' + d.getDate(),
            year = d.getFullYear();

        if (month.length < 2) month = '0' + month;
        if (day.length < 2) day = '0' + day;

        return [year, month, day].join('-');
    }

    loadBranchData () {
        axios.get(APIURL + '/branch/')
            .then(res => this.setState({branch: res.data})).catch(error => {
            this.setState({branch: []})
        })
    }

    loadCountPeriodData () {
        axios.get(APIURL + '/count_period/')
        .then(res => this.setState({count_period: res.data}))
        .catch(error => {
            this.setState({count_period: []})
        })
    }

    loadDurationPeriodData () {
        axios.get(APIURL + '/duration_period/')
          .then(res => this.setState({duration_period: res.data}))
          .catch(error => {
            this.setState({duration_period: []})
        })
    }

    async componentDidMount() {

        let debtors_type_id = this.props.debtors_type_id

        await this.loadBranchData()
        await this.loadCountPeriodData()
        await this.loadDurationPeriodData()
        await this.loadBankData()
        await this.loadDebtorType()

        // for dev
        // this.setState({
        //     customer_id: 97,
        //     branch_id: 1,
        //     debtors_name: "ซื้อประกันรถ",
        //     amount_of_debt: 0,
        //     duration_period_id: 9,
        //     amount_pay_period: 0,
        //     count_period_id: 1,
        //     date_pay_start: "2023-05-07",
        //     debtors_other: "",
        //     status_field: "show",
        //     username: "bosscar",
        //     debtors_type: 4,
        //     bank_id: 0,
        //     car_id: 174,
        //     amount_before_interest: 12000,
        //     amount_interest: 5,
        //     interest_type: "ดอกเบี้ยคงที่",
        //     interest_rate: 5,
        //     // debtors_detail: []
        // });

        if (debtors_type_id !== '') {
            let car = this.props.car
            // let car_id = this.props.car_id
            let customer_id = this.props.customer_id
            let debtors_name = this.props.debtors_name
            let amount_of_debt = this.props.amount_of_debt
            this.setState({
                debtors_type_id: debtors_type_id,
                debtors_name: debtors_name,
                amount_of_debt: amount_of_debt,
                amount_before_interest: amount_of_debt
            }, () => {
                car = {...car, customer_id: customer_id }
                this.onCarSelect(car)
            })

        }

    }

    componentWillMount() {
        // console.log(this.props.user)
        this.setState({
            user: JSON.parse(this.props.user)
        });
    }

    loadBankData() {
        axios.get( APIURL + '/bank/').then(res => {
            this.setState({
                bank_list: res.data
            })
        }).catch(error => {

        })
    }

    loadDebtorType() {
        // debtors_type
        axios.get( APIURL + '/debtors_type/').then(res => {
            this.setState({
                debtors_type: res.data
            })
        }).catch(error => {

        })
    }

    handleOnSubmitAddDebtors(event) {
        event.preventDefault()

        let customer_id = this.state.customer_id

        let branch_id = this.refs.branch_id.value
        let debtors_name = this.refs.debtors_name.value
        let amount_of_debt = this.refs.amount_of_debt.value
        let duration_period_id = this.refs.duration_period_id.value
        let amount_pay_period = this.refs.amount_pay_period.value
        let count_period_id = this.refs.count_period_id.value
        let date_pay_start = moment(this.state.date_pay).format('YYYY-MM-DD')
        let debtors_other = this.refs.debtors_other.value
        let bank_id = this.refs.bank_id.value
        let debtors_type_id = event.target.debtors_type_id.value

        let amount_before_interest = this.state.amount_before_interest
        let amount_interest = this.state.amount_interest

        let car_sell_id = this.props.car_sell_id
        let interest_rate = this.state.interest_rate

        /*console.log('customer_id===', customer_id)
        console.log('branch_id===', branch_id)
        console.log('duration_period_id===', duration_period_id)
        console.log('count_period_id===', count_period_id)
        console.log('amount_of_debt===', amount_of_debt)
        console.log('interest_rate===', interest_rate)
        return;*/

        if (debtors_type_id === '') {
            AlertWarning('กรุณาเลือกประเภทหนี้')
            return;
        }

        debtors_type_id = Number(event.target.debtors_type_id.value)

        // || debtors_type_id === 6 ลูกหนี้ค้างค่างวด [sompong]
        if (debtors_type_id === 1 || debtors_type_id === 2 || debtors_type_id === 4 || debtors_type_id === 6) {
            if (this.state.car_id === '') {
                AlertWarning('กรุณาเลือกรถยนต์')
                return;
            }
        }

        if(customer_id === ''){
            AlertWarning('กรุณาเลือกชื่อลูกค้าที่ต้องตั้งหนี้')
            return;
        }
        if(branch_id === ''){
            AlertWarning('กรุณาเลือกสาขา')
            return;
        }
        if(duration_period_id === ''){
            AlertWarning('กรุณาเลือกจำนวนงวดที่ต้องชำระ')
            return;
        }
        if(count_period_id === ''){
            AlertWarning('กรุณาเลือกระยะเวลาต่องวด')
            return;
        }
        if(amount_of_debt === '' || amount_of_debt === 0){
            AlertWarning("กรุณากรอกข้อมูลจำนวนหนี้")
            return;
        }
        if(interest_rate === ''){
            AlertWarning("กรุณากรอกข้อมูลดอกเบี้ย")
            return;
        }

        // ยกเลิก เปลี่ยนเป็นคำนวณจาก calculateInterest
        // if (this.state.interest_type === 'ดอกเบี้ยลดต้นลดดอก') {
        //     amount_of_debt = Number(amount_before_interest)
        // }

        let debtors_detail = this.state.pay_details
        let car_id = this.state.car_id
        let interest_type = this.state.interest_type
        let user = this.state.user.user
        let userFullname = this.state.user.fullname

        // let amount_before_interest = this.state.amount_before_interest
        let sum_amount_pay_period = debtors_detail.reduce((acc, next) => acc+ Number(next.amount_pay_period), 0)
        if (sum_amount_pay_period > Number(amount_before_interest ) && interest_rate === 0) {
            AlertWarning("ยอดรวมต่องวดเกินยอดหนี้ตั้งต้น\nกรุณาตรวจสอบ")
            return;
        }


        let data = {
            customer_id : customer_id,
            branch_id : branch_id,
            debtors_name : debtors_name,
            amount_of_debt : amount_of_debt,
            duration_period_id : duration_period_id,
            amount_pay_period : amount_pay_period,
            count_period_id : count_period_id,
            date_pay_start : date_pay_start,
            debtors_other : debtors_other,
            status_field: 'show',
            username: user,
            // debtors_type: 3,
            debtors_type: debtors_type_id,
            bank_id: bank_id,
            car_id: car_id,
            amount_before_interest: amount_before_interest,
            amount_interest: amount_interest,
            interest_type: interest_type,
            interest_rate: interest_rate,
            debtors_detail: debtors_detail,
            car_sell_id: car_sell_id
        }

        let debtors_types = this.state.debtors_type.filter(type => {
            return Number(type.debtors_type_id) === Number(debtors_type_id)
        })
        let debtors_type_name = debtors_types[0].debtors_type_name


        let duration_period_list = this.state.duration_period.filter(duration_period => {
            return Number(duration_period.duration_period_id) === Number(duration_period_id)
        })

        let duration_period_name = duration_period_list[0].duration_period_name
        let fullname = this.state.customer.customer_name+' '+this.state.customer.customer_lastname
        let customer_mobile = this.state.customer.customer_mobile
        let amount_pay_period2 = Number.isInteger(amount_pay_period) ? parseInt(String(amount_pay_period)) : amount_pay_period

        let message = '💰เพิ่มข้อมูลลูกหนี้ \n' +
          '✅ หัวข้อ : ' + debtors_name +'\n' +
          '✅ ประเภทหนี้ : ' + debtors_type_name +'\n' +
          '✅ ลูกค้า : ' + fullname +'\n' +
          '✅ เบอร์โทร : ' + customer_mobile +'\n' +
          '✅ จำนวนงวด : ' + duration_period_name +'\n' +
          '✅ ยอดผ่อนต่องวด : ' + NumberUtil.addCommas(String(amount_pay_period2)) +'\n' +
          '✅ ยอดหนี้รวม : ' + NumberUtil.addCommas(String(amount_of_debt)) +'\n' +
          '✅ ผู้ทำรายการ : ' + userFullname

        // console.log(message)
        // return;
        // เดิม => APIURL + '/debtors/
        axios.post(APIURL + '/debtors/interest_type', data)
            .then(res => {
                if(res.status === 200) {
                    LineNotify.sendDebtPayNotify(message)
                    if (car_sell_id !== null && car_sell_id !== ''){
                     this.setState({
                         is_redirect : true
                     },()=>{
                         this.props.onToggle()
                     })
                     //console.log('car_sell_id===', car_sell_id, typeof car_sell_id)
                    }else {
                        this.props.onToggle()
                    }
                }
            })
            .catch(error => {
                console.log("error: "+error)
            })
    }

    exportToExcel(){
        let pay_details = this.state.pay_details
        //TODO : exportToExcel
        if (pay_details.length === 0){
            AlertError('กรุณากรอกข้อมูลค่างวดให้ครบถ้วน')
            return
        }

        const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const fileExtension = '.xlsx';

        let curr = format(new Date(), 'dd/MM/yyyy HH:mm:ss')
        let currFileName = format(new Date(), 'yyyy-MM-dd')

        let customer_name = this.state.customer.customer_name + ' ' + this.state.customer.customer_lastname
        let car_name = this.state.car_select.car_name
        let car_license = this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
            this.state.car_select.car_license_plate_new : this.state.car_select.car_license_plate_old
        let province_name = this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
            this.state.car_select.province_name_new : this.state.car_select.province_name_old
        const title = [
            {
                A: 'หัวข้อ :  ' + this.state.debtors_name,
            },
            {
                A: 'ข้อมูลลูกค้า : ' + customer_name,
            },
            {
                A: 'ข้อมูลรถ : ' + car_name + ' ' + 'ทะเบียน : ' + car_license +' ' + province_name,
            },
            {
                A: 'พิมพ์เมื่อ : ' + curr,
            }
        ]

        let rows_data = [
            {
                A: 'งวดที่',
                B: 'วันที่กำหนดชำระ',
                C: 'ยอดชำระ',
                D: 'เงินต้น',
                E: 'ดอกเบี้ย',
            },
        ]

        pay_details.forEach(row => {
            let newRow = {
                A: row.order,
                B: moment(row.pay_date).locale('th').format('LL'),
                C: Number(row.amount_pay_period),
                D:  Number(row.capital),
                E:  Number(row.interest_period_bath),
            }
            rows_data.push(newRow)
        })

        const reportData = [...title, ...rows_data,]

        // const cellTable = {
        //     top: { style: 'thin', color: { rgb: "000000" } },
        //     bottom: { style: 'thin', color: { rgb: "000000" } },
        //     left: { style: 'thin', color: { rgb: "000000" } },
        //     right: { style: 'thin', color: { rgb: "000000" } },
        // }

        const cellTable = ExcelUtil.getBorderCell();

        setTimeout(() => {
            const ws = XLSX.utils.json_to_sheet(reportData, { origin: 'A1', skipHeader: true });

            let wscols = [
                {wch:10},
                {wch:20},
                {wch:16},
                {wch:16},
                {wch:16}
            ];

            let last_row = pay_details.length + 4

            let wsrows = [];
            for(let  i=1 ; i <=last_row + 1; i++){
                wsrows.push({hpt: 20})
            }

            ws['!cols'] = wscols;
            ws['!rows'] = wsrows

            let rangeTitle = { s: {r:4, c:0}, e: {r:4, c:4} };
            ExcelUtil.setCellBoldWithBorder(ws, rangeTitle);

            let rangeOrder = { s: {r:5, c:0}, e: {r:last_row, c:0} };
            ExcelUtil.setCellCenterNormalWithBorder(ws, rangeOrder);

            let rangeNumber = { s: {r:5, c:1}, e: {r:last_row, c:4} };
            ExcelUtil.setCellNumberFormatWIthBorder(ws, rangeNumber);

            const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], {type: fileType});
            let fileName = 'report_'+'_'+currFileName
            FileSaver.saveAs(data, fileName + fileExtension);


        }, 1200)


    }

    onChangePayDetail(index, order, key_name, value) {

        let interest_rate = this.state.interest_rate
        let amount_before_interest = this.state.amount_before_interest

        let pay_details = this.state.pay_details
        let pay_details_old = this.state.pay_details_old
        let pay_detail = pay_details.filter(pay => pay.order === order)[0]
        let pay_detail_old = pay_details_old.filter(pay => pay.order === order)[0]

        if (key_name === 'amount_pay_period') {
            pay_detail.amount_pay_period = value
            if (interest_rate === '0') {
                pay_detail.capital = parseFloat(value).toFixed(2)
                pay_detail.interest_period_bath = '0.00'
            }
        }

        let sum_amount_pay_period = pay_details.reduce((acc, next) => acc+ Number(next.amount_pay_period), 0)

        if (sum_amount_pay_period > Number(amount_before_interest)) {
            AlertWarning("ยอดชำระรวมเกินยอดหนี้ตั้งต้น\nกรุณาตรวจสอบ")
            // pay_detail.amount_pay_period = amount_pay_period_old
            // pay_detail.capital = capital_old
            // pay_detail.interest_period_bath = interest_period_bath_old
            // console.log('pay_detail_old=', pay_detail_old)
            pay_details[index] = pay_detail_old

            this.setState({
                pay_details: pay_details
            });
        } else {
            pay_details[index] = pay_detail
            this.setState({
                pay_details: pay_details
            });
        }
    }

    render(){

        if(this.state.is_redirect){
            return <Redirect push to={'/debtors/list'}/>
        }

        let bank_list = this.state.bank_list.map((bank, index) => {
            return(
              <option key={index} value={bank.bank_id}>{bank.bank_name}</option>
            )
        })

        let car_branch_list = this.state.branch.map((branch,index)=>{
            return(
                <option key={index} value={branch.branch_id}>{branch.branch_name}</option>
            )
        })

        let debtors_type_list = this.state.debtors_type.map((type, index) => {
            return (
              <option key={index} value={type.debtors_type_id}>{type.debtors_type_name}</option>
            )
        })

        let count_period_list = this.state.count_period.map((count_period,index)=>{
            return(
                <option key={index} value={count_period.count_period_id}>{count_period.count_period_name}</option>
            )
        })

        let duration_period_list = this.state.duration_period.map((duration_period,index)=>{
            return(
                <option key={index} value={duration_period.duration_period_id}>{duration_period.duration_period_name}</option>
            )
        })

        let search_customer_list = this.state.customer_list.map((customer_list,index)=>{
            return(
                <tr key={index}>
                    <td className="text-center"><strong>ชื่อ</strong>
                        <br/>
                        {customer_list.customer_name}&nbsp;&nbsp;&nbsp;{customer_list.customer_lastname}
                     </td>
                    <td className="text-center"><strong>เลขประจำตัวประชาชน</strong>
                        <br/>
                        {customer_list.customer_card_id}
                    </td>
                    <td className="text-center"><strong>เบอร์โทรศัพท์</strong>
                        <br/>
                        {customer_list.customer_mobile}
                    </td>
                    <td>
                        <button
                            onClick={()=>this.onCustomerSelect(customer_list)}
                            type="button"
                            className="btn btn-primary"
                        >
                         <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                        </button>
                    </td>
                </tr>
            )
        })

        let search_car_list = this.state.car_lists.map((car,index)=>{

            let car_license_plate = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
                car.car_license_plate_new : car.car_license_plate_old
            let province_name = car.car_license_plate_new !== '' &&  car.car_license_plate_new !==  car.car_license_plate_old ?
                car.province_name_new : car.province_name_old

            return(
              <tr key={index}>
                  <td className="text-left">
                      {car.car_name}
                  </td>
                  <td className="text-left">
                      ทะเบียน: {car_license_plate} &nbsp;{province_name}
                  </td>
                  <td>
                      <button
                        onClick={()=>this.onCarSelect(car)}
                        type="button"
                        className="btn btn-primary"
                      >
                          <i className="fa fa-hand-pointer-o"/>&nbsp;&nbsp; เลือก
                      </button>
                  </td>
              </tr>
            )
        })


            return(
            <div className="card card-accent-primary">
                <form action=""
                      method="post"
                      onSubmit={this.handleOnSubmitAddDebtors}
                >
                    <div className="card-header">
                        <strong className="text-title">เพิ่มข้อมูลลูกหนี้</strong>
                    </div>
                    <div className="card-block">
                        <div className="row">
                            <div className="form-group col-sm-6">
                                <label htmlFor="debtors_name">หัวข้อ</label>
                                <input type="text"
                                       className="form-control"
                                       id="debtors_name"
                                       name="debtors_name"
                                       ref="debtors_name"
                                       value={this.state.debtors_name || ''}
                                       placeholder=""
                                       onChange={(e) => {
                                           this.setState({
                                               debtors_name: e.target.value
                                           })
                                       }}
                                />
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="debtors_type_id">ประเภทหนี้</label>
                                <select className="form-control"
                                        id="debtors_type_id"
                                        name="debtors_type_id"
                                        value={this.state.debtors_type_id}
                                        onChange={(e) => {
                                            this.setState({
                                                debtors_type_id: e.target.value
                                            })
                                        }}
                                >
                                    <option value=''> เลือกประเภทหนี้ </option>
                                    {debtors_type_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">สาขา</label>
                                <select className="form-control"
                                        id="branch_id"
                                        name="branch_id"
                                        ref="branch_id"
                                >
                                    {car_branch_list}
                                </select>
                            </div>

                            <div className="form-group col-sm-6">
                                <label htmlFor="branch">ช่องทางการรับชำระ(ธนาคาร)</label>
                                <select className="form-control"
                                        id="bank_id"
                                        name="bank_id"
                                        ref="bank_id"
                                >
                                    <option value={0}>ไม่ระบุ</option>
                                    {bank_list}
                                </select>
                            </div>

                            <div className="card-block">

                                <div className="row">
                                    <div className="form-group col-sm-6">
                                        <div className="row">
                                            <div className="controls col-12">
                                                <div className="input-group ">
                                                    <input onChange={this.searchCar}
                                                           name="car_search"
                                                           placeholder="ค้นหาตามทะเบียนรถ เฉพาะรถที่ขายแล้ว (อย่างน้อย 3 ตัวอักษร)"
                                                           className="form-control"
                                                           size="16"
                                                           type="text"/>

                                                    <span className="input-group-btn ">
                                                        <button className="btn btn-sm btn-success"
                                                                type="button"
                                                        >
                                                            ค้นหา
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div className={ this.state.is_car_search ? " col-12 pl-2" : ' hidden' }>
                                                <table className="table table-striped ml-2">
                                                    <tbody>
                                                     {search_car_list}
                                                    </tbody>
                                                </table>
                                            </div>

                                            <div className={this.state.is_car_select ? 'col-12 mt-2' : ' hidden'}>
                                                <div className=" form-group row" style={styles.bottomLine}>
                                                    <label className="col-3"><strong> ชื่อรถ </strong></label>
                                                    <div className="col-9">
                                                        <p className="text-justify text-success">
                                                            <strong> {this.state.car_select.car_name} </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className=" form-group row" style={styles.bottomLine}>
                                                    <label className="col-3"><strong> ทะเบียน </strong></label>
                                                    <div className="col-9">
                                                        <p className="text-justify text-success">
                                                            <strong>
                                                                { this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
                                                                    this.state.car_select.car_license_plate_new : this.state.car_select.car_license_plate_old}
                                                                &nbsp;
                                                                {this.state.car_select.car_license_plate_new !== '' &&  this.state.car_select.car_license_plate_new !==  this.state.car_select.car_license_plate_old ?
                                                                    this.state.car_select.province_name_new : this.state.car_select.province_name_old}
                                                            </strong>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <div className="row">
                                            <div className="controls col-12">
                                                <div className="input-group ">
                                                    <input onChange={this.searchCustomer}
                                                           ref="search_customer_name"
                                                           placeholder="กรอกเพื่อค้นหาชื่อลูกค้า (อย่างน้อย 3 ตัวอักษร)"
                                                           className="form-control"
                                                           size="16"
                                                           type="text"/>

                                                    <span className="input-group-btn ">
                                                        <button className="btn btn-sm btn-success"
                                                                type="button"
                                                          >ค้นหา
                                                        </button>
                                                    </span>
                                                </div>
                                            </div>

                                            <div  className={ this.state.is_customer_search ? "col-12 pl-2" : ' hidden' }>
                                                <table className="table table-striped">
                                                    <tbody>
                                                    {search_customer_list}
                                                    </tbody>
                                                </table>
                                            </div>


                                            <div className={this.state.is_customer_select ? 'col-12 mt-2' : ' hidden'}>
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-4"><strong> ชื่อ - นามสกุลลูกค้า </strong></label>
                                                        <div className="col-8">
                                                            <p className="text-justify text-success">
                                                                <strong>{this.state.customer.customer_name} &nbsp;&nbsp; {this.state.customer.customer_lastname}</strong></p>
                                                        </div>
                                                    </div>
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-4"><strong> เลขบัตรประชาชน </strong></label>
                                                        <div className="col-8">
                                                            <p className="text-justify text-success">
                                                                <strong>{this.state.customer.customer_card_id}</strong></p>
                                                        </div>
                                                    </div>
                                                    <div className=" form-group row" style={styles.bottomLine}>
                                                        <label className="col-4"><strong> เบอร์โทรศัพท์ </strong></label>
                                                        <div className="col-8">
                                                            <p className="text-justify text-success">
                                                                <strong> {this.state.customer.customer_mobile} </strong></p>
                                                        </div>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-12" style={styles.bottomLine}>
                                    </div>
                                </div>

                                <div className="row mt-2">
                                    <div className="form-group col-12">
                                        ประเภทการคิดคอกเบี้ย
                                        &nbsp;&nbsp;
                                        <input type="radio"
                                               name="interest_type"
                                               value="ดอกเบี้ยคงที่"
                                               className="btn btn-primary"
                                               checked={this.state.interest_type === 'ดอกเบี้ยคงที่'}
                                               onChange={this.onInterestTypeChange}
                                        /> &nbsp;<span style={{marginTop: 0}}>ดอกเบี้ยคงที่</span>
                                        &nbsp;&nbsp;&nbsp;&nbsp;
                                        <input type="radio"
                                               name="interest_type"
                                               value="ดอกเบี้ยลดต้นลดดอก"
                                               className="btn btn-primary"
                                               checked={this.state.interest_type === 'ดอกเบี้ยลดต้นลดดอก'}
                                               onChange={this.onInterestTypeChange}
                                        /> &nbsp;<span style={{marginTop: 0}}>ดอกเบี้ยลดต้นลดดอก</span>
                                    </div>

                                    <div className="form-group col-6">
                                        <label htmlFor="amount_before_interest">ยอดหนี้ตั้งต้น(ก่อนรวมดอกเบี้ย)</label>
                                        <NumberFormat
                                          className="form-control"
                                          thousandSeparator={true}
                                          prefix={'฿'}
                                          // placeholder="กรอกค่าโอน"
                                          min={0}
                                          max={1000000000}
                                          step={1000}
                                          size={10}
                                          allowNegative={false}
                                          value={this.state.amount_before_interest}
                                          onValueChange = {(values) => {
                                              const {value} = values;
                                              this.setState({
                                                  amount_before_interest: value
                                              },  () => {
                                                  this.calculateInterest()
                                              })
                                          }}
                                        />
                                    </div>

                                    <div className="form-group col-6">
                                        <label htmlFor="interest_rate">ดอกเบี้ยร้อยละต่อปี (ระบุ 0 กรณีไม่คิดดอกเบี้ย)</label>
                                        <NumberFormat
                                          className="form-control"
                                          thousandSeparator={true}
                                          prefix={'%'}
                                          // placeholder="กรอกค่าโอน"
                                          min={0}
                                          max={1000000000}
                                          step={1000}
                                          size={10}
                                          allowNegative={false}
                                          onValueChange = {(values) => {
                                              const {value} = values;
                                              this.setState({interest_rate: value},()=>{
                                                  if(value !== ''){
                                                      this.calculateInterest()
                                                  }
                                              })
                                          }}
                                        />
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="duration_period_id">จำนวนงวดที่ต้องชำระ</label>
                                        <select
                                          className="form-control"
                                          id="duration_period_id"
                                          ref="duration_period_id"
                                          name="duration_period_id"
                                          onChange={(e) => {
                                              let v = e.target.value
                                              this.calculateInterest()
                                          }}
                                        >
                                            {duration_period_list}
                                        </select>
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="count_period_id">ระยะเวลาต่องวด (ระยะห่างในการชำระในแต่ละงวด)</label>
                                        <select
                                          className="form-control"
                                          id="count_period_id"
                                          ref="count_period_id"
                                          name="count_period_id"
                                          onChange={() => this.calculateInterest()}
                                        >
                                            {count_period_list}
                                        </select>
                                    </div>

                                    {/*<div className="form-group col-sm-12 card-accent-primary">
                                        <input type="text"
                                               className="form-control"
                                               name="calculate_note"
                                               placeholder="calculate note"
                                               value={this.state.calculate_note}
                                               onChange={() => {

                                               }}
                                        />
                                    </div>*/}


                                    <div className="form-group col-sm-6">
                                        <label htmlFor="amount_pay_period">จำนวนเงินต่องวด</label>
                                        <input type="text"
                                               className="form-control hidden"
                                               id="amount_pay_period"
                                               ref="amount_pay_period"
                                               placeholder=""
                                               value={this.state.amount_pay_period}
                                               onChange={()=>{}}
                                        />
                                        <NumberFormat
                                          className="form-control"
                                          thousandSeparator={true}
                                          prefix={'฿'}
                                          //placeholder="กรอกค่าโอน"
                                          min={0}
                                          max={1000000000}
                                          step={1000}
                                          size={10}
                                          allowNegative={false}
                                          value={this.state.amount_pay_period}
                                          onValueChange = {(values) => {
                                              const {value} = values;
                                              this.setState({amount_pay_period: value},()=>{
                                              })
                                          }}
                                          onBlur={() => {
                                              this.calculateInterest()
                                          }}
                                        />
                                    </div>

                                    <div className="form-group col-sm-6">
                                        <label htmlFor="date_pay_start">วันเริ่มต้นชำระเงิน</label>
                                        <br/>
                                        <DatePicker
                                          selected={this.state.date_pay}
                                          onChange={this.handleDatePayStart}
                                          dateFormat="yyyy-MM-dd"
                                          type="text"
                                          className="form-control"
                                          id="date_pay_start"
                                          ref="date_pay_start"
                                          name="date_pay_start"
                                          placeholder=""
                                        />
                                    </div>

                                    <div className= "form-group col-6"  >
                                        <label htmlFor="amount_of_debt">ดอกเบี้ย(บาท)</label>
                                        <NumberFormat
                                          className="form-control"
                                          thousandSeparator={true}
                                          prefix={'฿'}
                                          //placeholder="กรอกค่าโอน"
                                          min={0}
                                          max={1000000000}
                                          step={1000}
                                          size={10}
                                          allowNegative={false}
                                          value={this.state.amount_interest}
                                          onValueChange = {(values) => {
                                              // const {value} = values;
                                              //
                                              // this.setState({amount_of_debt: value},()=>{
                                              // })
                                          }}
                                        />
                                    </div>

                                    <div className= "form-group col-6" >
                                        <label htmlFor="amount_of_debt">จำนวนหนี้(รวมดอกเบี้ย)</label>
                                        <input type="text"
                                               className="form-control hidden"
                                               id="amount_of_debt"
                                               ref="amount_of_debt"
                                               placeholder=""
                                               value={this.state.amount_of_debt}
                                               onChange={()=>{}}
                                        />
                                        <NumberFormat
                                            className="form-control"
                                            thousandSeparator={true}
                                            prefix={'฿'}
                                            //placeholder="กรอกค่าโอน"
                                            min={0}
                                            max={1000000000}
                                            step={1000}
                                            size={10}
                                            allowNegative={false}
                                            value={this.state.amount_of_debt}
                                            onValueChange = {(values) => {
                                                const {value} = values;
                                                this.setState({amount_of_debt: value});
                                            }}
                                        />
                                    </div>

                                    <div className={this.state.pay_details.length === 0 ? "hidden" : "form-group col-sm-12"}>

                                        <table className="table table-striped">
                                            <thead>
                                              <tr>
                                                  <th>งวดที่</th>
                                                  <th>วันที่กำหนดชำระ</th>
                                                  <th>ยอดชำระ</th>
                                                  <th>เงินต้น</th>
                                                  <th>ดอกเบี้ย</th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                this.state.pay_details.map((pay_detail, index) => (
                                                  <tr key={index}>
                                                      <td>{pay_detail.order}</td>
                                                      <td>{ moment(pay_detail.pay_date).locale('th').format('LL')}</td>
                                                      <td>
                                                          {
                                                             this.state.interest_rate === '0' ?
                                                                 <NumberFormat
                                                                     className="form-control"
                                                                     style={{marginTop: 0, width: 100}}
                                                                     thousandSeparator={true}
                                                                     prefix={'฿'}
                                                                     placeholder=""
                                                                     min={0}
                                                                     max={1000000000}
                                                                     step={1000}
                                                                     size={10}
                                                                     allowNegative={false}
                                                                     value={pay_detail.amount_pay_period}
                                                                     onValueChange = {(values) => {
                                                                         const {value} = values
                                                                         /// this.setState({tax_amount: value})
                                                                         this.onChangePayDetail(index, pay_detail.order, 'amount_pay_period', value)
                                                                     }}
                                                                 />
                                                                 : NumberUtil.addCommas(pay_detail.amount_pay_period)
                                                          }


                                                      </td>
                                                      <td>{pay_detail.capital}</td>
                                                      <td>{pay_detail.interest_period_bath}</td>
                                                  </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>

                                    <div className="form-group col-sm-12">
                                        <button type="button"
                                                className="btn  btn-success mr-2 float-right" style={styles.btnWidth}
                                                onClick={()=>{
                                                    this.exportToExcel()
                                                }}
                                        > Excel คำนวณงวด </button>
                                    </div>

                                    <div className="form-group col-sm-12">
                                        <label htmlFor="debtors_other">หมายเหตุ</label>
                                        <input type="text"
                                               className="form-control"
                                               id="debtors_other"
                                               ref="debtors_other"
                                               placeholder=""
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 text-center">
                            <div className="card-block">
                                <button type="reset"
                                        className="btn  btn-danger mr-2" style={styles.btnWidth}
                                        onClick={this.props.onToggle}
                                >
                                    <i className="fa fa-refresh"/> ยกเลิก</button>

                                <button type="submit"
                                        className="btn btn-success"
                                        style={styles.btnWidth}
                                >
                                    <i className="icon-check"/>  บันทึกข้อมูลลูกหนี้
                                </button>
                            </div>
                        </div>

                    </div>
                </form>
            </div>
        );
    }
}

const styles = {
    bottomLine: {
        borderBottomColor: '#ccc',
        borderBottomStyle: 'solid',
        borderWidth: 0.5,
        marginLeft: 8,
        marginRight: 8
    },
    btnWidth: {
        width: 170,
   }
}

const mapStateToProps = (state) => ({
    user: state.auth.user,
    token: state.auth.token,
    isAuthenticated: state.auth.isAuthenticated
})

export default connect(mapStateToProps)(DebtorsForm);
