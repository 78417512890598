
// https://www.svgviewer.dev/svg-to-data-uri
export const PDFUtil = {
   getCustomFonts() {
     return {
       IrisUPC: {
         normal: 'IrisUPC-Regular.ttf',
         bold: 'IrisUPC-Bold.ttf',
         italics: 'IrisUPC-Italic.ttf',
         bolditalics: 'IrisUPC-BoldItalic.ttf'
       },
       THSarabunNew: {
         normal: 'THSarabunNew-Regular.ttf',
         bold: 'THSarabunNew-Bold.ttf',
         italics: 'THSarabunNew-Italic.ttf',
         bolditalics: 'THSarabunNew-BoldItalic.ttf'
       },
       Roboto: {
         normal: 'Roboto-Regular.ttf',
         bold: 'Roboto-Medium.ttf',
         italics: 'Roboto-Italic.ttf',
         bolditalics: 'Roboto-MediumItalic.ttf'
       },
     }
   },
   getStarImageBase64() {
    return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAAAmJLR0QA/4ePzL8AAAUkSURBVGjexZlrbFRFGIanrbRcWyRIoqImSqFQDMjdEC+IqUiECNQQxRBpAgkSbhGtgdZACGK8gCkSAVOEaqopmhDBCAmXVKQtsO3Oe8waEn6QCIqK2tA/EIg8/pjd0ttuz9Kzy5k/mz0z877fd2a++b53jLnth0wV61N9aueSadL/hHrZg8I1ezDUK+0EtC4GL4TWptv+PO+KCPMcMwgjvCuhvLQSsOVCvIvBsNl9hvI0wjfkqlmEGYbB8HDUB+GBaf7+72Oi7QOETd86UD/9Iyz5rQScD2zz2QFpIeCVCvFhK7zB8BFCeKVpsd/7W1hGtCPwCGGE/o30Tz2BNUJsbQdvMGx1e+GNFMOf7+39IURhJwIFWIS9HOqbWvtXCVHRCd5gqHAxcVVK7delru03GEY6H/xV1yd18W+5EJ90CW8wbHfrYHmK4M/l2N+FGBuXwKMuIP15vndqvv/rQuyIC28w7HDrYGlKzn/9KsS4hATGICz2t0h28PFviRC7EsIbDLtcTFwSnOOHhKfaRdrkXRJiQrcExjoCl7TJLgpP1ZDbgIwMspO8Bd4GVSvktbTNeyq7hTcYKttmSngtCqna2+At0OTIoLjnuzfezvfKVKUG29wu1WrTLJN8EZiIJe4czWpQlVdm53vjG3Kdiz+2FxV3wFF2s54SppNPji94gyGHfKZTwnp2c5T4s9uL2mo6/llLFRtZTBEF9PMNmaj1pYAiFrORKmo7kTDiNNVsZikzKSQ3EMhELZdCZrKUzVRz2hEYkXLQeK3AERhzxwiMdQRqGXlH4EdS6wiIE4xOO/xoTkQXYUiIeh8xLljn17kwFTKhPNUJ0eAzzATRxkXh1RgZZIxRP3tciFM8nhb4CdQ760+21hGhvvaIEGd4OuXwUzntrP+xXQp/LsceECLEMymFfyIK7x3ulDlGssP7LaKRZ1MG/yRnnPU/dJm0RbL1rRBhXkgJfBGNzvqD53LiHMs1Wd4XjsLswOFn0OSs35dQzqnJ0h539s8PFH6Oqx3RV8fv6lb78iodhZcDg58Xhbdf1mT5kd8y7DZH4dVA4F+KZUi7fMt5ZHgVLl1Y2GP4hbHcsIKM5MqwLULs7TGBvc75W5KvA6cJUd5jAuWOwLTk64EVQgEsxFdut2C1O4WY2GMCk9wK2JE0gXCdEHf3mMBA54GTySrhGWoRxwLZhscRXkuSeyDyoL9S1E/7DCF+fiC5Wvh5Id4KhECpiwQzktsDbwoxLxACxY7AmuQI7BFBVQyuYLefJ0egSVgGBEKgv1PPGpO7DboqDvmYfChDffQ6hNDVJG6WIsOE2N7NtINZSyONrGNwNz2dfBcZ5j8KzhFidYIp81jJqdYS+xQryUvQe7WLhi/634RlQsyKM10fSvgpdshe0zX3q56VcfWE2a5vmf8l+LVQlyVrL4pvaR437E57f9M99j1dj4kbJV3qKKOi6Zh/D/wiwp2myqSI72OW31RNOD/Wv+khu9P7z705TDFZnSSbMMJG/EuS18V3HSaZwr5b2s4R+1jHUU2jVOPddO/3M4vMdqMPIHTDp3yp0R2vJKZQfUvVqfeeijtyso7F+n1DUefrjMIk9sCy1jhWGRXphG2yRd2OLrKNiqahla3C9jI3xxx/HigWohTD8NglBEJnVezvSCVD83Q2Nm4bwzG87QjM9UWg6T63qg/Eigl0wS7yldG3rbFe0wU3OuxWALoeutdvIHqnjZJ3WSvi1nKJ42m2Xa7LbWTJLi93/wcQGER4HEJb1AAAAABJRU5ErkJggg=='
   }
}
